





























import CalendarItemWrapper from '@/modules/common/components/ui-kit/calendar-item-wrapper.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import Day from '@/modules/common/types/day.type';
import Month from '@/modules/common/types/month.type';
import Year from '@/modules/common/types/year.type';
import ASSESSMENT_TYPES from '@/modules/common/constants/assessments-types.constant';
import CalendarEventsContainer from '@/modules/events/components/calendar-events-container.vue';
import EventTypesPoints from '@/modules/common/components/event-types-points.vue';
import MarketsService, { MarketsServiceS } from '@/modules/markets/markets.service';
import Occupancy from '@/modules/occupancy/components/occupancy.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import isDayType from '@/modules/common/component-validators/is-date.validator';
import getParentNonModalRoute from '@/modules/common/filters/no-modal-route.filter';

@Component({
    components: {
        CalendarEventsContainer,
        CalendarItemWrapper,
        Occupancy,
        EventTypesPoints,
    },
})
export default class MarketsCalendarItem extends Vue {
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(MarketsServiceS) private marketsService!: MarketsService;
    @Inject(UserServiceS) userService!: UserService;

    @Prop({
        required: true,
        type: Number,
        validator: isDayType,
    })
    private day!: Day;

    @Prop({
        required: true,
        type: String,
    })
    private source!: string;

    myCardColor(day: number) {
        const color = this.marketsService.getCardAssessment(day as Day, this.source);

        if (!this.hotelId) {
            return null;
        }

        return {
            high: color === ASSESSMENT_TYPES.GOOD,
            'med-high': color === ASSESSMENT_TYPES.NORMAL,
            'med-low': color === ASSESSMENT_TYPES.FAIR,
            low: color === ASSESSMENT_TYPES.BAD,
        };
    }

    get targetRoute() {
        const { userService, day, source } = this;
        const userLevel = userService.isViewAsChain
            ? 'chain.'
            : '';

        return {
            name: `markets.${userLevel}hotel.calendar.day-markets-source`,
            params: {
                day: String(day),
                source: String(source),
            },
        };
    }

    get hotelId() {
        return this.$route.params.hotelId || this.userService.currentHotelId!;
    }

    get month(): Month {
        return this.documentFiltersService.storeState.settings.month;
    }

    get year(): Year {
        return this.documentFiltersService.storeState.settings.year;
    }

    get position(): number | null {
        return this.marketsService.myPosition(this.day, this.source);
    }

    get dayRoute() {
        const nonModalRouteName = getParentNonModalRoute(this.$router);
        const isDayMarketsOpened = nonModalRouteName.includes('day-markets-source');
        return {
            name: isDayMarketsOpened ? nonModalRouteName : `${nonModalRouteName}.day-markets-source`,
            params: { day: String(this.day), source: String(this.source) },
        };
    }
}

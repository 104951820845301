

















import { Component, Vue } from 'vue-property-decorator';
import MarketsCalendarLabel from '@/modules/markets/components/calendar/markets-calendar-label.vue';
import MarketsActions from '@/modules/markets/components/markets-actions.vue';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import MarketsHeader from '@/modules/markets/components/markets-header.vue';
import MarketsVisibility from '@/modules/markets/components/markets-visibility.vue';

@Component({
    components: {
        MarketsVisibility,
        MarketsCalendarLabel,
        MarketsActions,
        PageWrapper,
        MarketsHeader,
    },
})
export default class MarketsCalendarPage extends Vue {}

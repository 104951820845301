











import { Component, Vue, Prop } from 'vue-property-decorator';
import Day from '@/modules/common/types/day.type';
import isDayType from '@/modules/common/component-validators/is-date.validator';

@Component
export default class MarketsCalendarItemEmpty extends Vue {
    @Prop({
        required: true,
        type: Number,
        validator: isDayType,
    })
    day!: Day;
}
